<template>
  <div>
    <a-modal
      :visible="visible"
      title="服务选择"
      width="55%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-select
        style="width: 100%"
        v-model="selectedRowKeys"
        allowClear
        mode="multiple"
        showSearch
        :showArrow="false"
        v-if="tableDate.length"
        optionFilterProp="label"
        :open="false"
      >
        <a-select-option v-for="service in services" :label="service.identifier" :key="service.id">{{
          service.identifier
        }}</a-select-option>
      </a-select>
      <div class="property-operations">
        <div></div>
        <div class="data-filter">
          <a-input
            clearable
            placeholder="请输入标识符或备注内容"
            v-model="searchKeyword"
            class="search-keyword"
            @keyup.enter.native="listProductService"
          ></a-input>
          <a-button class="search-btn" type="primary" @click="listProductService()">搜索</a-button>
        </div>
      </div>
      <a-table
        size="middle"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        :columns="this.$constRepository.productService.columns"
        :data-source="tableDate"
        class="property-table"
        rowKey="id"
      >
        <template slot="description" slot-scope="scope">{{
          scope.defaultMessage ? scope.defaultMessage : '暂无'
        }}</template>
        <template slot="action" slot-scope="scope">
          <a-popover trigger="click" title="服务字段" placement="left">
            <template #content>
              <div class="popover-content">
                <a-descriptions bordered>
                  <a-descriptions-item label="服务项标识符">
                    {{ scope.identifier }}
                  </a-descriptions-item>
                  <a-descriptions-item label="服务描述">
                    {{ scope.description.defaultMessage }}
                  </a-descriptions-item>
                  <a-descriptions-item label="超时时间">
                    {{ scope.timeout }}
                  </a-descriptions-item>
                </a-descriptions>
                <template v-if="scope.parameters.length > 0">
                  <h3 style="margin-top: 1rem">参数项</h3>
                  <a-descriptions
                    v-for="parameter in scope.parameters"
                    :key="parameter.identifier"
                    bordered
                    style="margin-top: 1%"
                  >
                    <a-descriptions-item label="参数项标识符"> {{ parameter.identifier }} </a-descriptions-item>
                    <a-descriptions-item label="参数项名称">
                      {{ parameter.description.defaultMessage }}
                    </a-descriptions-item>
                    <a-descriptions-item label="属性类型">
                      <span>{{ propertyType(parameter) }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="编辑方式">
                      <span>{{ propertyEditMode(parameter) }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="默认值">
                      <span>{{ parameter.type.defaultValue || '无' }}</span>
                    </a-descriptions-item>
                    <template v-if="parameter.type.editMode == $constRepository.productFormItem.editMode.input">
                      <a-descriptions-item label="范围">
                        <span v-if="parameter.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
                          `${parameter.type.range.min}-${parameter.type.range.max}`
                        }}</span>
                        <span v-else>无</span>
                      </a-descriptions-item>
                    </template>
                    <template v-if="parameter.type.editMode == $constRepository.productFormItem.editMode.select">
                      <a-descriptions-item label="选项">
                        <p v-for="(option, index) in parameter.type.options" :key="index">
                          {{ `${option.value}:${option.description.defaultMessage}` }}
                        </p>
                      </a-descriptions-item>
                    </template>
                  </a-descriptions>
                </template>
              </div>
            </template>
            <a-button type="link">查看</a-button>
          </a-popover>
        </template>
        <template slot="timeout" slot-scope="scope">{{ scope ? `${scope}ms` : '暂无' }}</template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'ProductServiceSelector',
  components: {},
  data() {
    return {
      searchKeyword: '',
      visible: false,
      selectType: 'checkbox',
      resultCallBack: {
        onSubmit: Function,
      },
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      selectedRowKeys: [],
      tableDate: [],
      services: [],
      metaData: {},
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    hideDialog() {
      this.visible = false;
    },
    async listProductService() {
      const response = await this.$apiManager.productService.getProductService({
        unpaged: true,
        searchKey: this.searchKeyword,
      });
      this.tableDate = response.data.elements;
      this.services = !this.services.length ? response.data.elements : this.services;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async showCreateDialog(callback) {
      await Promise.all([this.getMetaData(), this.listProductService()]);
      // 将已选择置于表格前;
      const target = [];
      this.tableDate = this.tableDate.filter((item) => {
        if (!this.selectedRowKeys.includes(item.id)) {
          return true;
        }
        target.push(item);
        return false;
      });
      this.tableDate = [...target, ...this.tableDate];
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    showUpdateDialog(pararms, callback) {
      this.listProductService();
      this.productCategoryForm = pararms;
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      const keys = this.selectedRowKeys.map((key) => ({ id: key }));
      const params = lodash.intersectionBy(this.services, keys, 'id');
      this.resultCallBack.onSubmit(this.selectedRowKeys, params);
      this.hideDialog();
    },
  },
  computed: {
    propertyType() {
      return (property) => {
        const propertyName = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        return propertyName ? propertyName.name : '';
      };
    },
    propertyEditMode() {
      return (property) => {
        const modeName = this.$constRepository.productFormItem.editModeText[property.type.editMode];
        return modeName || '';
      };
    },
  },
};
</script>

<style lang="less" scoped>
.popover-content {
  max-height: 400px;
  overflow-y: auto;
}
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.property-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    // margin: 10px 0px;
    // margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }

}
.property-table {
    margin-top: 1%;
  }
.pagination {
  margin-top: 3%;
}
</style>
